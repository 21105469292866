import * as React from "react";

import CenterContainer from "../components/center-container";
import Helmet from "react-helmet";
import "./index.css";

import FavIcon from "../img/favicon.png";

//  bgColor="#f0efe4"

const IndexPage = () => {
  return (
    <html lang="en">
      <head>
        <Helmet>
          <title>grac's bytes</title>
          <link rel="icon" href={FavIcon} />
        </Helmet>
      </head>
      <body style={{ backgroundColor: "#f0efe4" }}>
        <div style={{ display: "none" }}>
          <img
            src="https://hitwebcounter.com/counter/counter.php?page=7934349&style=0007&nbdigits=6&type=ip&initCount=0"
            title="Free Counter"
            Alt="web counter"
            border="0"
          />
        </div>
        <CenterContainer>
          <div id="home-page">
            <div id="home-items">
              <div id="home-grac" />

              <div id="home-right-items">
                {/* <a href="/about"> */}
                  <div id="home-henlo" />
                {/* </a> */}

                {/* <a href="/music">
                  <div id="home-music">
                    <img src={Music} alt="music" style={{ opacity: "0%" }} />
                  </div>
                </a>

                <a href="/misc">
                  <div id="home-misc">
                    <img src={Misc} alt="misc" style={{ opacity: "0%" }} />
                  </div>
                </a> */}
              </div>
            </div>
          </div>
        </CenterContainer>
      </body>
    </html>
  );
};

export default IndexPage;
